import React, { useState } from "react";
import { enqueueSnackbar } from "notistack";

import InputForm from "../../UI/Forms/InputForm/InputForm";
import { Button } from "@mui/material";
import {
  registrationForm,
  loginForm,
  forgotPassword,
  registrationFormWhenDeliveryDisabled,
} from "../../UI/Forms/Forms";
import { checkValidity } from "../../UI/Forms/Validation";

import { v4 as uuidv4 } from "uuid";
import "./UserForm.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  userDialogToggle,
  registerUser,
  loginUser,
  sendTempPassword,
  deleteAccountToggle,
  profileToggle,
} from "../../../ToolKit/Slices/UserSlice";
import Reaptcha from "reaptcha";

const UserFormF = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  // redux states
  const userAction = useSelector((state) => state.user.userAction);
  const selectedArea = useSelector((state) => state.order.selectedArea);
  const deliveryAreas = useSelector((state) => state.shop.deliveryAreas);
  const deliveryON = useSelector((state) => state.shop.deliveryON);
  const orderType = useSelector((state) => state.cart.orderType);
  const isDeliveryDisable = useSelector(
    (state) => state.shop.settings?.isDeliveryDisable
  );
  const [verified, setVerified] = useState(false);

  // local states
  const [state, setState] = useState({
    userForm:
      userAction === "login"
        ? loginForm
        : userAction === "register"
        ? isDeliveryDisable
          ? registrationFormWhenDeliveryDisabled
          : registrationForm
        : forgotPassword,
    formIsValid: false,
    userActionLocal: userAction,
  });
  const onVerify = (e) => {
    setVerified(true);
  };
  //   local functions
  const registerHandler = (formDetails) => {
    let formData = {};
    for (let formElementIdentifier in formDetails) {
      formData[formElementIdentifier] =
        formDetails[formElementIdentifier].value;
    }
    formData = {
      ...formData,
      addrId: isDeliveryDisable ? undefined : uuidv4(),
      city: selectedArea.area,
      postalCode: selectedArea.postalCode,
      userAreaId: selectedArea.id,
      selected: true,
    };

    dispatch(registerUser(formData));
  };

  const loginHandler = (formDetails) => {
    dispatch(
      loginUser({ formDetails, areas: deliveryAreas, deliveryON, orderType })
    );
    if (window.location.pathname === "/delete-account") {
      dispatch(deleteAccountToggle(true));
      dispatch(profileToggle(true));
    }
  };

  const sendEmailHandler = (formData) => {
    dispatch(sendTempPassword(formData.email.value));
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedForm = {
      ...state.userForm,
    };
    const updatedFormElement = {
      ...updatedForm[inputIdentifier],
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      userAction !== "forgotPassword" ? state.userForm.password.value : null
    );
    updatedFormElement.touched = true;
    updatedForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    setState({ ...state, userForm: updatedForm, formIsValid: formIsValid });
  };

  const { userForm, formIsValid } = state;

  console.log("userForm", userForm);
  console.log("formIsValid", formIsValid);

  const formElementsArray = [];
  for (let key in userForm) {
    formElementsArray.push({
      id: key,
      config: userForm[key],
    });
  }
  let form = (
    <form className="UserForm">
      {formElementsArray.map((formElement) => (
        <InputForm
          key={formElement.id}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={(event) => inputChangedHandler(event, formElement.id)}
        />
      ))}
    </form>
  );

  console.log("selectedArea.id", selectedArea.id);
  console.log("formIsValid", formIsValid);
  console.log("verified", verified);

  return (
    <div>
      {form}
      {state.userActionLocal === "register" ? (
        <div>
          <Reaptcha
            className="my-2"
            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
            onVerify={onVerify}
          />
          <div className="UserButton">
            {formIsValid && selectedArea.id ? null : (
              <span style={{ fontSize: 10, color: "red" }}>
                {t("userDialog.registerError")}{" "}
              </span>
            )}
            <Button
              fullWidth
              onClick={() => registerHandler(state.userForm)}
              variant="contained"
              color="primary"
              disabled={
                !formIsValid ||
                (!isDeliveryDisable && !selectedArea.id) ||
                !verified
                  ? true
                  : false
              }
            >
              {t("button.register")}
            </Button>
          </div>
        </div>
      ) : state.userActionLocal === "login" ? (
        <div className="UserButton">
          <p
            style={{
              float: "left",
              fontSize: "13px",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                userDialogToggle({ toggle: true, userAction: "forgotPassword" })
              );
              setState({
                ...state,
                userForm: forgotPassword,
                userActionLocal: "forgotPassword",
              });
            }}
          >
            {t("userDialog.forgetPassword")}
          </p>
          <Button
            style={{ marginRight: "5px", textDecoration: "underline" }}
            onClick={() => {
              if (window.location.pathname === "/delete-account") {
                window.location.pathname = "/";
              }
              dispatch(
                userDialogToggle({ toggle: true, userAction: "register" })
              );
              setState({
                ...state,
                userForm: registrationForm,
                userActionLocal: "register",
              });
            }}
            variant="text"
            color="primary"
          >
            {t("button.register")}
          </Button>
          <Button
            onClick={() => loginHandler(state.userForm)}
            variant="contained"
            color="primary"
            disabled={!formIsValid}
          >
            {t("button.login")}
          </Button>
        </div>
      ) : (
        <>
          <Button
            style={{ float: "right", marginTop: "10px", marginBottom: "10px" }}
            onClick={() => sendEmailHandler(state.userForm)}
            variant="contained"
            color="primary"
            disabled={!formIsValid}
          >
            {t("button.send")}
          </Button>
        </>
      )}
    </div>
  );
};

export default UserFormF;
