import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import {
  TodayTiming,
  checkTimeLies,
  checkUpcomingHolidays,
  checkIfManifestIsPresent,
  setServerTimeLocal,
  calculateTimings,
  findIntervals,
  checkShopStatus,
  findLowestAndHighest,
} from "./utils";

// importing actions
import { setDeliveryCharge, setOrderType, setVoucherDiscount } from "./CartSlice";
import { addNotification } from "./NotifierSlice";
import { selectAddress, selectAddressLocal, setTheme } from "./UserSlice";
import { getPaymentsMethodsStripe, selectArea } from "./OrderSlice";
import { t } from "i18next";

// Initial State

const initialState = {
  isDeliveryAvailable: { status: true },
  isPickupAvailable: { status: true },
  deliveryAndPickupTimes: null,
  shopData: null,
  paymentMethods: null,
  shopTimings: null,
  shopTimingsModified: null,
  // deliveryAreas: null,
  deliveryAreas:[],
  discounts: null,
  holidayTiming: null,
  homepage: "",

  loading: null,
  error: null,

  voucherLoading: null,
  currVoucher: null,

  shopDataVisible: false,
  timeDialogVisible: false,
  manuallyClosed: false,
  termsVisible: false,
  policyVisible: false,

  todayTiming: null,
  shopCurrOpen: null,
  isOrderPossible: true,

  settings: {},
  paypal: {},

  isError: false,
  errorSent: false,
  runtimeError: null,
  infoClicked: false,

  deliveryON: true,
  pickupON: true,
  delayedTimeIntervals: null,
  pickupTimeIntervals: null,
  deliveryTimeIntervals: null,
  futureOrderIntervals: null,
  serverTime: null,
  serverDate: null,
  isShopAboutToClose: null,
  isShopAboutToOpen: null,
  serverTimeDateEpoch: null,
  vacationsDialogOpen: false,

  vacationActiveToday: false,
  comingHolidays: null,
  isManifestPresent: false,
  isShopCurrentlyOpen: null,
  shopOpeningDate: null,
  shopClosingDate: null,
};

// ASYNC THUNKS

// 1. FETCH SHOP

export const fetchShop = createAsyncThunk("/shop", async (params, thunkApi) => {
  const { rejectWithValue, dispatch } = thunkApi;

  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/shop`);

    const deliveryON = response.data[0][0].settings.hasOwnProperty("deliveryON")
      ? response.data[0][0].settings.deliveryON
      : true;

    const userPersist = JSON.parse(window.localStorage.getItem("persist:user"));

    if (userPersist?.isUserTheme === "false") {
      if (response.data[0][0].homepage.themeModeDark) {
        dispatch(setTheme("dark"));
      } else {
        dispatch(setTheme("light"));
      }
    }
    // console.log("params.currUser", params.currUser);
    // console.log("params.userOrders", params.userOrders);
    const areas = response.data[3];
    if (deliveryON) {
      if (params.currUser && (Array.isArray(params.userOrders) ? params.userOrders[0] : false)) {
        // console.log("curr user present");

        // checking if last order type then set it to that order type
        const lastOrder =
          params.userOrders.length > 0 ? params.userOrders[params.userOrders.length - 1] : null;
        if (lastOrder) {
          dispatch(
            setOrderType({
              orderTypeLocal: lastOrder.orderType,
              deliveryCharges: lastOrder.deliveryCharges,
            })
          );
        }

        // storing the curruser selected address if delivery and in cart
        if (lastOrder.orderType === "delivery") {
          let addresses = params.userAddresses;
          let selectedAddress = addresses.find((address) => address.selected === true);
          console.log("selectedAddress", selectedAddress);
          if (selectedAddress) {
            // if user saved address is available in db
            let selectedAddressAvailable = areas?.find(
              (area) => area.id === selectedAddress?.userAreaId
            );
            if (selectedAddressAvailable === undefined || !selectedAddressAvailable?.active) {
              dispatch(
                addNotification({
                  key: 1,
                  message: `${selectedAddress?.city}, ${selectedAddress?.postalCode}. ${t(
                    "Delivery not available at this time"
                  )}`,
                  type: "error",
                  persist: false,
                })
              );
            } else {
              const areaHandler = (areaId) => {
                const area = areas.filter((area) => area.id === areaId);
                return area[0];
              };
              // dispatch(selectAddressLocal({ addrId: selectedAddress.addrId }));
              // dispatch(
              //   selectAddress({ userId: params.currUser.id, addrId: selectedAddress.addrId })
              // );
              // dispatch(selectAddressLocal({ addrId: selectedAddress.addrId }));
              dispatch(selectArea(areaHandler(selectedAddress.userAreaId)));
              dispatch(setDeliveryCharge(areaHandler(selectedAddress.userAreaId).deliveryCharges));
            }
          }
        }
      }
    } else {
      dispatch(setOrderType({ orderTypeLocal: "", deliveryCharges: 0 }));
    }
    // console.log("response.error", response.error);
    if (response.status === 200) {
      const paymentMethods = response.data?.[1];
      if (paymentMethods) {
        const isStripeActive = paymentMethods?.find((el) => el.method === "Stripe" && el.isActive);
        if (isStripeActive) {
          // console.log("isStripeActive", isStripeActive);

          dispatch(getPaymentsMethodsStripe());
        }
      }
      // console.log(
      //   "TodayTiming(response.data[2], response.data[4], response.data[5].serverDate)",
      //   TodayTiming(response.data[2], response.data[4], response.data[5].serverDate)
      // );
      const payloadForIntervalsDispatch = {
        preOrderStartTime: response.data[0][0].settings?.preOrderStartTime,
        serverTime: response.data[5].serverHourMinuteTimeFormatString,
        todayTiming: TodayTiming(response.data[2], response.data[4], response.data[5].serverDate),
        separateTimings: response.data[0][0].settings?.separateTimings,
      };
      // const payloadForOpenShopDispatch = {
      //   serverTime: response.data[5].serverHourMinuteTimeFormatString,
      //   todayTiming: TodayTiming(response.data[2], response.data[4], response.data[5].serverDate),
      //   manualClosing: response.data[0][0].settings?.manualClosing,
      //   vacationsClosing: response.data[0][0].settings?.vacationsClosing,
      //   serverDate: response.data[5].serverDate,
      // };

      // dispatch(checkIsShopCurrentlyOpen(payloadForOpenShopDispatch));
      // console.log("payloadForIntervalsDispatch", payloadForIntervalsDispatch);
      dispatch(calculateShopTimeIntervals(payloadForIntervalsDispatch));
    }

    return response.data;
  } catch (error) {
    setTimeout(() => {
      window.location.reload();
    }, 10000);
    console.log("error", error.message);
    return rejectWithValue(error.message);
  }
});

// 2. CHECK VOUCHER

export const checkVoucher = createAsyncThunk("/shop/check_voucher", async (voucher, thunkApi) => {
  const { rejectWithValue, dispatch } = thunkApi;

  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/shop/check_voucher`,
      voucher
    );

    if (response.status === 200) {
      dispatch(
        addNotification({
          key: 1,
          message: "Voucher applied successfully!",
          type: "success",
          persist: false,
        })
      );
      dispatch(setVoucherDiscount({ voucher: response.data }));
    }

    // if (response.status === 401 || response.status === 500) {
    //   dispatch(
    //     addNotification({
    //       key: 1,
    //       message: "Invalid Voucher Entered!",
    //       type: "error",
    //       persist: false,
    //     })
    //   );
    // }

    return response.data;
  } catch (error) {
    console.log("error", error);
    dispatch(
      addNotification({
        key: 1,
        message: error.response.data,
        type: "error",
        persist: true,
      })
    );
    return rejectWithValue(error.response.data);
  }
});

export const ShopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    updatePickupDeliveryTime(state, action) {
      state.settings = {
        ...state.settings,
        livePanelSettings: {
          ...state.settings.livePanelSettings,
          defaultPickupTime: action.payload.pickupTime,
          defaultDeliveryTime: action.payload.deliveryTime,
        },
      };
    },
    setFutureOrderIntervals(state, action) {
      state.futureOrderIntervals = action.payload;
    },
    setComingHolidays(state, action) {
      state.comingHolidays = action.payload;
    },
    setServerTime(state, action) {
      const newTime = setServerTimeLocal(state.serverTime);
      state.serverTime = Number(newTime);
    },
    setIsShopCurrentlyOpen(state, action) {
      state.isShopCurrentlyOpen = action.payload;
    },
    setLocalClientDateTime(state, action) {
      state.serverTime = action.payload.time;
      state.serverDate = action.payload.date;
    },
    shopDataToggle(state, action) {
      return {
        ...state,
        shopDataVisible: action.payload,
      };
    },
    vacationsDialogToggle(state, action) {
      state.vacationsDialogOpen = action.payload;
    },
    timeDialogToggle(state, action) {
      return {
        ...state,
        timeDialogVisible: action.payload,
      };
    },
    termsDialogToggle(state, action) {
      return {
        ...state,
        termsVisible: action.payload,
      };
    },
    policyDialogToggle(state, action) {
      return {
        ...state,
        policyVisible: action.payload,
      };
    },
    setTodayTiming(state, action) {
      return {
        ...state,
        todayTiming: action.payload.timing,
      };
    },
    resetVoucher(state) {
      return {
        ...state,
        currVoucher: null,
      };
    },
    setOrderPossible(state, action) {
      return {
        ...state,
        isOrderPossible: action.payload,
      };
    },
    setRuntimeError(state, action) {
      return {
        ...state,
        runtimeError: { error: action.payload.error, info: action.payload.info },
        isError: true,
      };
    },
    setErrorSent(state, action) {
      return {
        ...state,
        errorSent: action.payload.sent,
      };
    },
    setInfoClicked(state, action) {
      return { ...state, infoClicked: action.payload };
    },

    setIntervals(state, action) {
      state.delayedTimeIntervals = action.payload;
    },

    calculateShopTimeIntervals(state, action) {
      const { todayTiming, serverTime, preOrderStartTime, separateTimings } = action.payload;
      // console.log("separateTimings", separateTimings);

      let todayTimingToSend = "";
      let localClientTimeToSend = "";
      let preOrderStartTimeToSend = "";
      let separateTimingsToSend = "";

      if (todayTiming && preOrderStartTime && serverTime && separateTimings !== undefined) {
        todayTimingToSend = todayTiming;
        localClientTimeToSend = Number(serverTime);
        preOrderStartTimeToSend = preOrderStartTime;
        separateTimingsToSend = separateTimings;
      } else {
        todayTimingToSend = state.todayTiming;
        localClientTimeToSend = Number(state.serverTime);
        preOrderStartTimeToSend = state.settings.preOrderStartTime;
        separateTimingsToSend = state.settings?.separateTimings;
      }

      // console.log("todayTimingToSend", todayTimingToSend);

      let normalIntervals = null;
      let pickupIntervals = null;
      let deliveryIntervals = null;

      normalIntervals = findIntervals(
        todayTimingToSend?.timings,
        localClientTimeToSend,
        preOrderStartTimeToSend
      );

      // console.log('normalIntervals', normalIntervals)

      if (separateTimingsToSend) {
        pickupIntervals = findIntervals(
          todayTimingToSend?.pickupTimings,
          localClientTimeToSend,
          preOrderStartTimeToSend
        );

        deliveryIntervals = findIntervals(
          todayTimingToSend?.deliveryTimings,
          localClientTimeToSend,
          preOrderStartTimeToSend
        );
      } else {
        normalIntervals = findIntervals(
          todayTimingToSend?.timings,
          localClientTimeToSend,
          preOrderStartTimeToSend
        );
      }

      // console.log("deliveryIntervals", deliveryIntervals);

      // if (separateTimings) {
      //   const intervals = findIntervals(
      //     todayTimingToSend?.timings,
      //     localClientTimeToSend,
      //     preOrderStartTimeToSend
      //   );
      //   return {
      //     ...state,
      //     delayedTimeIntervals: intervals,
      //   };
      // }

      // // check where the client area time lies  , before opening or after closing
      // let closestHours = findClosestHours(todayTimingToSend, localClientTimeToSend);
      // // calculate intervals
      // let intervals = null;

      // if (todayTimingToSend.timings.length < 2) {
      //   //  if shop has only 1 set of timings
      //   intervals = calculateTimeIntervals(
      //     closestHours[0],
      //     closestHours[1],
      //     preOrderStartTimeToSend
      //   );
      // } else if (todayTimingToSend.timings.length > 1) {
      //   //  if shop has more than 1 set of timings
      //   for (let index = 0; index < todayTimingToSend?.timings?.length; index++) {
      //     const timeArray = todayTimingToSend?.timings[index];
      //     const openingTime = timeArray[0];
      //     const closingTime = timeArray[1];

      //     const array = calculateTimeIntervals(openingTime, closingTime, preOrderStartTimeToSend);
      //     if (index > 0) {
      //       intervals = intervals.concat(array);
      //     } else {
      //       intervals = array;
      //     }
      //   }
      // }

      return {
        ...state,
        delayedTimeIntervals: normalIntervals,
        pickupTimeIntervals: pickupIntervals,
        deliveryTimeIntervals: deliveryIntervals,
      };
    },
    // calculatePickupTimeIntervals(state,action){

    // },

    checkIsShopCurrentlyOpen(state, action) {
      const {
        todayTiming,
        serverTime,
        manualClosing,
        vacationsClosing,
        serverDate,
        separateTimings,
      } = action.payload;
      let todayTimingToSend = "";
      let localClientTimeToSend = "";
      let manualClosingToSend = "";
      let vacationsClosingSend = "";
      let serverDateToSend = "";
      let separateTimingsToSend = "";

      if (
        todayTiming &&
        serverTime &&
        manualClosing &&
        vacationsClosing &&
        serverDate &&
        separateTimings
      ) {
        todayTimingToSend = todayTiming;
        localClientTimeToSend = serverTime;
        manualClosingToSend = manualClosing;
        vacationsClosingSend = vacationsClosing;
        serverDateToSend = serverDate;
        separateTimingsToSend = separateTimings;
      } else {
        todayTimingToSend = current(state.todayTiming);
        localClientTimeToSend = state.serverTime;
        manualClosingToSend = state.settings?.manualClosing;
        vacationsClosingSend = state.settings?.vacationsClosing;
        serverDateToSend = state.serverDate;
        separateTimingsToSend = state.settings?.separateTimings;
      }

      let infoObject = {
        todayTimingToSend,
        localClientTimeToSend,
        manualClosingToSend,
        vacationsClosingSend,
        serverDateToSend,
        separateTimingsToSend,
      };

      // console.log('todayTimingToSend', current(todayTimingToSend))

      let result = null;
      let deliveryAndPickupTimes = null;
      // console.log("separateTimingsToSend", separateTimingsToSend);
      // console.log("todayTimingToSend", todayTimingToSend);
      // && !todayTimingToSend?.holiday
      if (separateTimingsToSend) {
        // console.log("do i run?");

        // ###############################################################################
        // ######################### IF SEPARATE TIMINGS IS "ON" ###########################
        // ###############################################################################
        // const pickUpTimeLies = checkTimeLies(
        //   todayTimingToSend.pickupTimings,
        //   Number(localClientTimeToSend)
        // );
        // console.log("pickUpTimeLies", pickUpTimeLies);

        // const pickupClosingTime = todayTimingToSend.pickupTimings?.[pickUpTimeLies?.index]?.[1];
        
        // console.log("pickupClosingTime", pickupClosingTime);
        // const pickupClosingTime = todayTimingToSend.pickupTimings?.[0]?.[1];
        // it will always be the same (because opening time will always be at index [0][0])

        // const deliveryOpeningTime = todayTimingToSend.deliveryTimings?.[0]?.[0];

        // let todayPickupTimingsToSend = [...todayTimingToSend.pickupTimings];
      
        // if (Number(pickupClosingTime) < Number(deliveryOpeningTime)) {
        //   console.log("Less");

        //   todayPickupTimingsToSend = [
        //     ...todayTimingToSend.pickupTimings,
        //     ...todayTimingToSend.deliveryTimings,
        //   ];
        // }
    

        const isDeliveryTime = checkTimeLies(
          todayTimingToSend.deliveryTimings,
          Number(localClientTimeToSend)
          // Number(action.payload[5].serverHourMinuteTimeFormatString)
        );

        const isPickupTime = checkTimeLies(
          todayTimingToSend.pickupTimings,
          // todayPickupTimingsToSend,
          Number(localClientTimeToSend)
          // Number(localClientTimeToSend)

          // Number(action.payload[5].serverHourMinuteTimeFormatString)
        );
        const currentDeiveryTime = todayTimingToSend?.deliveryTimings[isDeliveryTime?.index];
        const currentPickupTime = todayTimingToSend?.pickupTimings[isPickupTime?.index];
        
        const maxAndMin = findLowestAndHighest([...currentDeiveryTime, ...currentPickupTime]);
        // console.log("maxAndMin", maxAndMin);
        deliveryAndPickupTimes = [maxAndMin?.lowest?.toString(), maxAndMin?.highest?.toString()];

        // console.log("deliveryAndPickupTimes", deliveryAndPickupTimes);

        const newObj = {
          ...infoObject,
          todayTimingToSend: {
            ...infoObject?.todayTimingToSend,
            timings: [deliveryAndPickupTimes],
          },
        };

        result = checkShopStatus(newObj);
        // if (isDeliveryTime?.status || isPickupTime?.status) {
        //   console.log("Shop Is Open");
        // } else if (
        //   (!isDeliveryTime?.status && isDeliveryTime?.message === "less") ||
        //   (!isPickupTime?.status && isPickupTime?.message === "less")
        // ) {
        //   console.log("Future Order");
        // } else if (
        //   (!isDeliveryTime?.status && isDeliveryTime?.message === "greater") ||
        //   (!isPickupTime?.status && isPickupTime?.message === "greater")
        // ) {
        //   console.log("Shop Closed");
        // } else {
        //   console.log("Some Else");
        //   console.log("Shop Closed");
        // }
      } else {
        // ###############################################################################
        // ######################### WHEN SEPARATE TIMINGS IS "OFF" ###########################
        // ###############################################################################
        // result = checkShopStatus(infoObject);
        result = checkShopStatus(infoObject);
      }
      // console.log("result checkIsShopCurrentlyOpen=> ", result);
      const {
        todayShopClosingDateWithTime,
        todayShopOpeningDateWithTime,
        isShopAboutToOpen,
        isShopAboutToClose,
        isDeliveryTime,
        isPickupTime,
        shopTimeDialogVisible,
        shopVacationDialogVisible,
        isCurrentlyOpen,
        vacationToday,
        manuallyClosed,
        orderPossibility,
      } = result;

      state.deliveryAndPickupTimes = deliveryAndPickupTimes;
      state.shopClosingDate = todayShopClosingDateWithTime;
      state.shopOpeningDate = todayShopOpeningDateWithTime;
      state.isShopAboutToOpen = isShopAboutToOpen;
      state.isShopAboutToClose = isShopAboutToClose;
      state.isDeliveryAvailable = isDeliveryTime;
      state.isPickupAvailable = isPickupTime;
      // state.timeDialogVisible = shopTimeDialogVisible;
      // state.timeDialogVisible = separateTimingsToSend ? false : shopTimeDialogVisible;
      state.timeDialogVisible = separateTimingsToSend
        ? orderPossibility
          ? false
          : true
        : shopTimeDialogVisible;
      state.shopCurrOpen = !shopTimeDialogVisible;
      state.vacationsDialogOpen = shopVacationDialogVisible;
      state.isShopCurrentlyOpen = isCurrentlyOpen;
      state.vacationActiveToday = vacationToday;
      state.manuallyClosed = manuallyClosed;

      state.isOrderPossible = orderPossibility;
    },
    setIsDeliveryAvailable(state, action) {
      state.isDeliveryAvailable = action.payload;
    },
    setIsPickupAvailable(state, action) {
      state.isPickupAvailable = action.payload;
    },
  },
  extraReducers: {
    // FETCH SHOP
    [fetchShop.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    [fetchShop.fulfilled]: (state, action) => {
      // console.log("action in shop fulfilled", action);
      const todayTiming = TodayTiming(
        action.payload[2],
        action.payload[4],
        action.payload[5].serverDate
      );

      // console.log("todayTiming", todayTiming);

      const isDeliveryTime = checkTimeLies(
        todayTiming.deliveryTimings,
        Number(action.payload[5].serverHourMinuteTimeFormatString)
      );
      // console.log("isDeliveryTime", isDeliveryTime);
      const isPickupTime = checkTimeLies(
        todayTiming.pickupTimings,
        Number(action.payload[5].serverHourMinuteTimeFormatString)
      );

      // console.log("isPickupTime", isPickupTime);
      // console.log("isDeliveryTime", isDeliveryTime);
      // console.log("isPickupTime", isPickupTime);
      const serverDate = action.payload[5].serverDate;
      const upcomingHolidays = checkUpcomingHolidays(action.payload[4], serverDate);

      // new Date(year,month,day,hours,minutes)
      // const d = new Date(2023,11,20,8,0);
      const buildVersion = action.payload[0][0].settings?.version;
      const alreadyPresentBuildVersion = localStorage.getItem("build_version");
      const alreadyPresentSubscription = localStorage.getItem("subscription");
      const alreadyPresentJWTToken = localStorage.getItem("token");

      if (alreadyPresentBuildVersion ) {
        if (alreadyPresentBuildVersion !== buildVersion) {
          localStorage.clear();
          localStorage.setItem("build_version", buildVersion);
          localStorage.setItem("subscription", alreadyPresentSubscription);
          if(alreadyPresentJWTToken)
          {

            localStorage.setItem("token", alreadyPresentJWTToken);
          }

          window.location.reload();
        }
      } else {
        localStorage.setItem("build_version", buildVersion);
      }

      const serverTimeDateEpoch = new Date(serverDate).getTime();

      // check if manifest is present
      const isManifestPresent = checkIfManifestIsPresent(action.payload[0][0]);
      const shopTimesModified = calculateTimings(action.payload[2]);

      return {
        ...state,
        isDeliveryAvailable: isDeliveryTime,
        isPickupAvailable: isPickupTime,
        serverTime: Number(action.payload[5].serverHourMinuteTimeFormatString),
        serverDate: action.payload[5].serverDate,
        serverTimeDateEpoch: serverTimeDateEpoch,
        shopData: action.payload[0][0],
        settings: action.payload[0][0].settings,
        homepage: action.payload[0][0].homepage,
        paypal: action.payload[0][0].paypal,
        discounts: action.payload[0][0].discounts,
        paymentMethods: action.payload[1],
        shopTimings: action.payload[2],
        shopTimingsModified: shopTimesModified,
        deliveryAreas: action.payload[3],
        holidayTiming: action.payload[4],
        // manuallyClosed: manuallyClosed,
        deliveryON: action.payload[0][0].settings.hasOwnProperty("deliveryON")
          ? action.payload[0][0].settings.deliveryON
          : true,
        pickupON: action.payload[0][0].settings.hasOwnProperty("pickupON")
          ? action.payload[0][0].settings.pickupON
          : true,
        todayTiming: todayTiming,
        // timeDialogVisible: shopTimeDialogVisible,
        // shopCurrOpen: !shopTimeDialogVisible,
        loading: false,
        // vacationsDialogOpen: shopVacationDialogVisible,
        // vacationActiveToday: vacationToday,
        comingHolidays: upcomingHolidays,
        isManifestPresent: isManifestPresent,
        error: null,
      };
    },
    [fetchShop.rejected]: (state, action) => {
      return {
        ...state,
        error: action?.error?.message || "Error",
        loading: false,
        shopData: null,
        paymentMethods: null,
        shopTimings: null,
        deliveryAreas: null,
      };
    },
    // CHECK VOUCHER
    [checkVoucher.pending]: (state, action) => {
      return { ...state, voucherLoading: true };
    },
    [checkVoucher.fulfilled]: (state, action) => {
      return {
        ...state,
        currVoucher: action.payload,
        voucherLoading: false,
        error: null,
      };
    },
    [checkVoucher.rejected]: (state, action) => {
      return {
        ...state,
        currVoucher: null,
        voucherLoading: false,
        error: action.payload.error,
      };
    },
  },
});

// Some Async Remaining ...

export const {
  setIntervals,
  calculateShopTimeIntervals,
  setFutureOrderIntervals,
  vacationsDialogToggle,
  shopDataToggle,
  timeDialogToggle,
  termsDialogToggle,
  policyDialogToggle,
  setTodayTiming,
  resetVoucher,
  setOrderPossible,
  setRuntimeError,
  setErrorSent,
  setInfoClicked,
  setLocalClientDateTime,
  setServerTime,
  setIsShopCurrentlyOpen,
  checkIsShopCurrentlyOpen,
  updatePickupDeliveryTime,
  setIsDeliveryAvailable,
  setIsPickupAvailable,
} = ShopSlice.actions;
